<template>
  <div id="listbox">
    <img src="@/assets/img/Special/post2.png" />
    <ul class="lists">
      <li v-for="(item, index) in lists" :key="item.id">
        <div class="header">
          <div class="omit">{{ item.gcName }}</div>
          <div>NO.{{ index + 1 }}</div>
        </div>
        <!-- gcShape 0：卡号卡密 1：卡密 2：卡号短链 3：卡号短链验证码 -->
        <template v-if="[0].includes(item.gcShape)">
          <div class="info">
            <div>卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.cardPwd }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardPwd"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
        <div class="info" v-if="[1].includes(item.gcShape)">
          <div>卡&nbsp;&nbsp;&nbsp;密</div>
          <div class="omit">{{ item.cardPwd }}</div>
          <div
            class="btn"
            v-clipboard:copy="item.cardPwd"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <div class="info" v-if="[2].includes(item.gcShape)">
          <div>短&nbsp;&nbsp;&nbsp;链</div>
          <a class="omit link" :href="item.shortUrl">{{ item.shortUrl }}</a>
          <div
            class="btn"
            v-clipboard:copy="item.shortUrl"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            复制
          </div>
        </div>
        <template v-if="[3].includes(item.gcShape)">
          <div class="info">
            <div>卡&nbsp;&nbsp;&nbsp;号</div>
            <div class="omit">{{ item.cardNo }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.cardNo"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>短&nbsp;&nbsp;&nbsp;链</div>
            <a class="omit link" :href="item.shortUrl">{{ item.shortUrl }}</a>
            <div
              class="btn"
              v-clipboard:copy="item.shortUrl"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
          <div class="info">
            <div>密&nbsp;&nbsp;&nbsp;码</div>
            <div class="omit">{{ item.verifyCode }}</div>
            <div
              class="btn"
              v-clipboard:copy="item.verifyCode"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError"
            >
              复制
            </div>
          </div>
        </template>
      </li>
    </ul>
    <div class="lform explain des" v-if="describe">
      <div class="tips">券码使用说明</div>
      <div v-html="describe" />
    </div>
  </div>
</template>

<script>
import { listRedeemCode } from '@/api/SyncLink.js'
export default {
  data() {
    return {
      lists: [],
      describe: ''
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const { code, data, message } = await listRedeemCode(this.$route.query)
      if (code === 0) {
        this.lists = data?.outStorageDetailsVOList
        this.describe = data?.describe
      } else {
        this.$toast.fail({
          message: message
        })
      }
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/Special.less';
.lists {
  padding: 0 3.9vw 0vw !important;
}
.des {
  background: #ecf3fe;
  box-shadow: 0px 1px 2px 0px rgba(14, 49, 175, 0.4);
  border-radius: 30px;
  margin: 0 3.9vw;
}
.link {
  color: #fff;
  text-decoration: underline;
}
</style>
